import React from 'react';


const Privacy = () => (
  <div>
    Privacy Component
  </div>
);

export default Privacy;
