import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Privacy from "./pages/Privacy/Privacy";
import Home from "./pages/Home/Home";
import LinkedIn from "./pages/LinkedIn/LinkedIn";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/linkedin" component={LinkedIn} />
      </Switch>
    </Router>
  );
}

export default App;
