import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import linkedin from "./linkedin.png";
import "./Linkedin.scss";
import queryString from "query-string";
import axios from "axios";

axios.defaults.baseURL = "http://localhost:5000";

const LinkedIn = () => {
  const location = useLocation();

  const LinkedIn = {
    response_type: "code",
    client_id: "86j4qkcqvyx80a",
    redirect_uri: "http://localhost:3002/linkedin",
    state: "DCEeFWf45A53sdfKef424",
    scope: `r_liteprofile r_emailaddress w_member_social`
  };

  const profileURL = queryString.stringify(LinkedIn);
  const authURL = `https://www.linkedin.com/oauth/v2/authorization/?${profileURL}`;

  //const history = useHistory();
  useEffect(() => {
    let data: any = queryString.parse(location.search);
    console.log(data);
    if (data.state === LinkedIn.state) {
      localStorage.setItem("code", data.code);
      try {
        axios.get(`/api/auth/?code=${data.code}`).then((response: any) => {
          if (response.status === 200) {
            console.log(response);
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  });

  return (
    <div className="login">
      <a href={authURL}>
        <img src={linkedin} alt="login with linkedin" />
      </a>
    </div>
  );
};

export default LinkedIn;
